import React, {useEffect, useState, useRef} from 'react';
import {GroupedTicketTypesT, TableColumnT} from '../../../helpers/table';
import {
  EmailsMapT,
  GroupDetailTableColumnsTitles,
  GroupDetailTableDataT,
  GroupDetailTableFields,
  TicketTypeRow,
} from '../../../types/tickets';
import {ActionsItem, ActionsItemProps, AdmissionItem, InputEmailItem, InputNameItem, ItemProps} from './TableItems';
import {Modal, Typography, Dropdown} from 'antd';
import {ModalDescr} from '../../Dashboard/styles';
import {
  TableTitleSpan,
  TableTitleWrapper,
  ViewTable,
  TableItemWrapper,
  ValueItem,
  SearchWrapper,
  SearchInput,
  EnterButton,
  SearchBtn,
  SearchResultWrapper,
  ResultLabel,
  ClearButton,
  StyledTableWrapper,
  TableRootTitle,
} from './styles';
import {CloseOutlined, SearchOutlined} from '@ant-design/icons';
import {
  onUpdateGuestT,
  sendMultipleT,
  useAddGuestStateT,
  useEditGuest,
  useSetTicketStateT,
  useTicketsActionsT,
} from '../../../hooks/tickets';
import {ticketTypesToTable, usedMetricsT} from '../../../helpers/tickets';
import {TicketSendStatuses, TicketStatusTypes} from '../../../types/tickets';
import {CenteredLoader} from '../../../ui-kit/Loader';

const {Text} = Typography;

export enum ModalLvl {
  closed = 'closed',
  confirm = 'confirm',
  success = 'success',
  error = 'error',
}

type TableTitleProps = {
  title: string;
  note: string;
};

const TableTitle: React.FC<TableTitleProps> = ({title, note}) => {
  return (
    <TableTitleWrapper>
      <Text>{title}</Text>
      <TableTitleSpan>{note}</TableTitleSpan>
    </TableTitleWrapper>
  );
};

type GroupDetailTableT = {
  tickets?: GroupDetailTableDataT[];
  listLoading?: boolean;
  onUpdate?: useTicketsActionsT['onUpdate'];
  onDelete?: useTicketsActionsT['onDelete'];
  onSendTicket: (id?: string) => Promise<boolean>;
  ticketState: useSetTicketStateT;
  groupRefetch: () => void;
  addGuestData: useAddGuestStateT;
  sendMultiple?: sendMultipleT;
  loadingSend: boolean;
  viewMetrics?: TicketTypeRow[];
  usedTickets?: usedMetricsT;
  emailsMap?: EmailsMapT;
  // onUpdateTickets: onUpdateTicketsT;
  onUpdateGuest: onUpdateGuestT;
  searchTickets: (v: string) => void;
  pageWidth?: number;
  filter: TicketStatusTypes;
  setFilter: (v: TicketStatusTypes) => void;
  groupedTicketTypes: GroupedTicketTypesT;
};

type SearchProps = {
  value: string;
  setValue: (v: string) => void;
  searchTickets: (v: string) => void;
  showResult: (v: boolean) => void;
};

type SearchResultProps = SearchProps & {
  resQty?: number;
};

type ClearFilterProps = {
  setFilter: (v: TicketStatusTypes) => void;
};

export type emailState = Record<any, any>;
export type emailStateVal = {key: string; value?: string};
export type nameState = emailState;
export type nameStateVal = emailStateVal;

export const GroupDetailTable: React.FC<GroupDetailTableT> = ({
  tickets,
  listLoading,
  onDelete,
  viewMetrics,
  ticketState,
  addGuestData,
  sendMultiple,
  loadingSend,
  usedTickets,
  emailsMap,
  searchTickets,
  onUpdateGuest,
  pageWidth,
  filter,
  setFilter,
  groupedTicketTypes,
}) => {
  const {state, handleSetEmail, handleSetName} = ticketState;
  const {guestState, guestSetState} = addGuestData;
  const [focusedId, setFocusedId] = useState<string | undefined>('');
  const [editingId, setEditingId] = useState<string | undefined>('');
  const [delModalLvl, setDelModalLvl] = useState<ModalLvl>(ModalLvl.closed);
  const [sendModalLvl, setSendModalLvl] = useState<ModalLvl>(ModalLvl.closed);
  const setSendPopup = (lvl: ModalLvl) => setSendModalLvl(lvl);
  const setDelPopup = (lvl: ModalLvl) => setDelModalLvl(lvl);
  const resetEditingId = () => setEditingId('');
  const tableEl = useRef<HTMLHeadingElement>(null);
  const [isVisible, setIsVisible] = useState(false);
  const [isSearchRes, setIsSearchRes] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState('');

  const handleSend = (id?: string) => {
    setFocusedId(id);
    setSendPopup(ModalLvl.confirm);
  };
  const handleDelete = (id?: string) => {
    setFocusedId(id);
    setDelModalLvl(ModalLvl.confirm);
  };
  const onSend = (id?: string) => {
    setFocusedId?.(id);
    focusedId && sendMultiple?.([focusedId?.toString()]);
  };
  const sendWhenEdited = async () => {
    const isSent = tickets?.find((el) => el?.id === focusedId)?.actions?.ticketStatus === TicketSendStatuses.sent;
    isSent && focusedId && (await sendMultiple?.([focusedId?.toString()]));
  };
  const {eGuestState, handleEdit, onSave, loadingEdit} = useEditGuest(
    onUpdateGuest,
    tickets?.find((el) => el?.id === editingId),
    viewMetrics,
    usedTickets,
    emailsMap,
    resetEditingId,
    sendWhenEdited,
  );

  useEffect(() => {
    if (!tickets) return;
    const tableWidth = tableEl?.current?.querySelector('table')?.offsetWidth;
    const isVisible = tableWidth && pageWidth && tableWidth > pageWidth ? true : false;
    setIsVisible(isVisible);
  }, [!!tickets?.length]);

  useEffect(() => {
    if (delModalLvl === ModalLvl.confirm) {
      const email = tickets?.find((el) => el?.id === focusedId)?.email?.email;
      Modal.confirm({
        title: `Do you want to delete the guest ${email}?`,
        content: (
          <ModalDescr>
            Once you have confirmed, the guest will be removed from the group, and you won’t be able to make any further
            edits.
          </ModalDescr>
        ),
        okText: 'Yes, proceed to delete',
        onOk: async () => {
          await onDelete?.(focusedId);
          setDelPopup(ModalLvl.closed);
        },
        onCancel: () => setDelPopup(ModalLvl.closed),
      });
    }
    if (delModalLvl === ModalLvl.success) {
      Modal.success({
        title: `The code has been deleted!`,
        content: <ModalDescr>You will not see the code now.</ModalDescr>,
        okText: 'Done',
        onOk: () => {
          console.log('delete', focusedId);
          setDelPopup(ModalLvl.closed);
        },
      });
    }
  }, [delModalLvl]);

  useEffect(() => {
    const email = tickets?.find((el) => el?.id === focusedId)?.email?.email;
    const ticketStatus = tickets?.find((el) => el?.id === focusedId)?.actions?.ticketStatus;
    const isSent = ticketStatus === TicketSendStatuses.queued || ticketStatus === TicketSendStatuses.sent;
    const sendText = isSent ? 'resend' : 'send';

    if (sendModalLvl === ModalLvl.confirm) {
      Modal.confirm({
        title: `Are you ready to ${sendText} this code to ${email}?`,
        content: (
          <ModalDescr>
            Please make sure the email address is correct. With this action, your guest will receive an email with the
            ticket. This action cannot be undone.
          </ModalDescr>
        ),
        okText: 'Yes',
        onOk: async () => {
          onSend(focusedId);
          setSendPopup(ModalLvl.closed);
        },
        onCancel: () => setSendPopup(ModalLvl.closed),
      });
    }
  }, [sendModalLvl, focusedId]);

  const columns: TableColumnT[] = [
    {
      key: GroupDetailTableFields.name,
      title: (
        <TableItemWrapper>
          <ValueItem>Name</ValueItem>
          <ValueItem>Email</ValueItem>
        </TableItemWrapper>
      ),
      fixed: 'left',
      dataIndex: GroupDetailTableFields.name,
      render: (props: ItemProps) => (
        <TableItemWrapper>
          <InputNameItem
            {...props}
            focusId={focusedId}
            setName={handleSetName}
            nameState={state}
            setFocusId={setFocusedId}
            guestSetState={guestSetState}
            guestState={guestState}
            editingId={editingId}
            eGuestState={eGuestState}
            eGuestSetState={handleEdit}
          />
          <InputEmailItem
            {...props}
            focusId={focusedId}
            setEmail={handleSetEmail}
            emailState={state}
            setFocusId={setFocusedId}
            guestSetState={guestSetState}
            guestState={guestState}
            editingId={editingId}
            eGuestState={eGuestState}
            eGuestSetState={handleEdit}
          />
        </TableItemWrapper>
      ),
    },
    ...ticketTypesToTable(groupedTicketTypes).map((key) => ({
      key: key,
      title: <TableRootTitle>{key}</TableRootTitle>,
      dataIndex: key,
      children:
        groupedTicketTypes[key]?.map((el) => ({
          title: (
            <TableTitle
              title={el.ticketTypeName}
              note={`(${el?.ticketTypeAssigned || 0}/${el?.ticketTypeUploaded || 0})`}
            />
          ),
          dataIndex: el.ticketType,
          render: (props: ItemProps) => (
            <AdmissionItem
              {...props}
              focusId={focusedId}
              ticketType={el.ticketType}
              guestState={guestState}
              guestSetState={guestSetState}
              max={el?.ticketTypeUploaded}
              eGuestSetState={handleEdit}
              eGuestState={eGuestState}
              editingId={editingId}
            />
          ),
        })) || [],
    })),
    {
      key: GroupDetailTableFields.actions,
      title: (
        <>
          {GroupDetailTableColumnsTitles.actions}
          <Search
            searchTickets={searchTickets}
            showResult={setIsSearchRes}
            value={searchValue}
            setValue={setSearchValue}
          />
        </>
      ),
      dataIndex: GroupDetailTableFields.actions,
      fixed: 'right',
      render: (props: ActionsItemProps) => (
        <>
          <ActionsItem
            {...props}
            onSendTicket={handleSend}
            setFocusId={setFocusedId}
            focusedId={focusedId}
            editingId={editingId}
            loading={loadingSend || addGuestData.loadingSave || loadingEdit}
            emailState={state}
            guestState={guestState}
            onAddGuest={addGuestData.onAddGuest}
            sendMultiple={sendMultiple}
            setEditingId={setEditingId}
            eGuestState={eGuestState}
            onSave={onSave}
            onDelete={handleDelete}
          />
        </>
      ),
    },
  ];
  return (
    <>
      {filter !== TicketStatusTypes.all && <ClearFilter setFilter={setFilter} />}
      {isSearchRes && searchValue && (
        <SearchResult
          resQty={tickets && tickets.length - 1}
          showResult={setIsSearchRes}
          searchTickets={searchTickets}
          value={searchValue}
          setValue={setSearchValue}
        />
      )}
      <div ref={tableEl}>
        <StyledTableWrapper>
          {/* <ColumnsLabel>Ticket Types</ColumnsLabel> */}
          <ViewTable
            columns={columns}
            dataSource={tickets}
            showHeader={true}
            $addShadow={(viewMetrics?.length || 0) > 2}
          />
          <ValueItem>{isVisible && 'Swipe / Scroll to view more'}</ValueItem>
        </StyledTableWrapper>
      </div>
      {listLoading && <CenteredLoader />}
    </>
  );
};

const Search: React.FC<SearchProps> = ({value, setValue, searchTickets, showResult}) => {
  const [open, setOpen] = useState(false);
  const handleOpenChange = (flag: boolean) => {
    setOpen(flag);
    setTimeout(() => document?.getElementById('search-input-view')?.focus(), 100);
  };
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
    if (!e.target.value) searchTickets('');
  };
  const handleOnSearch = () => {
    searchTickets(value);
    setOpen(false);
    showResult(true);
  };

  const searchInput = (
    <SearchWrapper>
      <SearchInput
        prefix={<SearchOutlined />}
        placeholder="Search by name or email..."
        onChange={handleInputChange}
        onPressEnter={handleOnSearch}
        value={value}
        id="search-input-view"
      />
      <EnterButton onClick={handleOnSearch} disabled={!value}>
        Search
      </EnterButton>
    </SearchWrapper>
  );

  return (
    <SearchBtn>
      <Dropdown
        overlay={searchInput}
        trigger={['click']}
        placement="bottomRight"
        onVisibleChange={handleOpenChange}
        visible={open}>
        {open ? <CloseOutlined /> : <SearchOutlined />}
      </Dropdown>
    </SearchBtn>
  );
};

const SearchResult: React.FC<SearchResultProps> = ({resQty, showResult, value, setValue, searchTickets}) => {
  const onClearClick = () => {
    setValue('');
    searchTickets('');
    showResult(false);
  };

  return (
    <SearchResultWrapper>
      <ResultLabel>{`${resQty} Results for "${value}"`}</ResultLabel>
      <ClearButton onClick={onClearClick}>Clear Search</ClearButton>
    </SearchResultWrapper>
  );
};

const ClearFilter: React.FC<ClearFilterProps> = ({setFilter}) => {
  const onClearClick = () => {
    setFilter(TicketStatusTypes.all);
  };
  return (
    <SearchResultWrapper>
      <ClearButton onClick={onClearClick}>Clear Filter</ClearButton>
    </SearchResultWrapper>
  );
};

import React from 'react';
import {
  CreateButton,
  DashboardActions,
  DashboardCard,
  DashboardCardsWrapper,
  PageWrapper,
  DashboardHeaderWrapper,
  PaginationWrapper,
} from './styles';
import {DashboardCardT, DashboardTableDataT, PaginationObjT} from '../../types/dashboard';
import {route} from '../../constants/routes';
import {DashboardTable} from './Table';
import {MetricsT} from '../../queries/types';
import {Pagination, Spin, notification} from 'antd';

export const openNotifError = (message?: string) => {
  console.log('e:', message);
  notification['error']({
    message: 'Error',
    description: message,
  });
};

type DashboardProps = {
  goEdit: (id: string) => void;
  onDelete: (id: string) => void;
  tickets: DashboardTableDataT[];
  metrics: MetricsT;
  canAddEdit: boolean;
  loading?: boolean;
  searchTickets: (v: string) => void;
  pagination: PaginationObjT;
  metricsLoading?: boolean;
  isSearching?: boolean;
};

const pageSizeOptions = [10, 25, 50, 100];
export const DashboardPage: React.FC<DashboardProps> = ({
  goEdit,
  onDelete,
  tickets,
  metrics,
  canAddEdit,
  loading,
  searchTickets,
  pagination,
  metricsLoading,
  isSearching,
}) => {
  const cards: DashboardCardT[] = [
    {
      label: 'Total Groups',
      value: String(pagination?.total),
    },
    {
      label: 'Tickets Uploaded',
      value: String(metrics?.ticketsUploaded),
    },
    {
      label: 'Tickets Sent',
      value: String(metrics?.ticketsSent),
      tooltip: 'The total numbers of codes that clients send guests.',
    },
    // {
    //   label: 'Tickets Redeemed',
    //   value: String(metrics?.ticketsUsed),
    //   tooltip: 'The total numbers of guests who open their code emails.',
    // },
  ];

  return (
    <PageWrapper>
      <DashboardHeaderWrapper>
        <Spin spinning={metricsLoading}>
          <Cards cards={cards} />
        </Spin>
        <DashboardActions>
          {canAddEdit && <CreateButton to={route.createTicketGroup.path}>Create New Ticket Group</CreateButton>}
        </DashboardActions>
      </DashboardHeaderWrapper>

      <DashboardTable
        data={tickets}
        onDelete={onDelete}
        goEdit={goEdit}
        canAddEdit={canAddEdit}
        loading={loading}
        searchTickets={searchTickets}
        metricsLoading={metricsLoading}
      />
      <PaginationWrapper>
        {!isSearching && (
          <Pagination
            showSizeChanger
            onShowSizeChange={pagination.changeLimit}
            current={pagination.pageNumber}
            onChange={pagination.changePage}
            total={pagination.total}
            pageSizeOptions={pageSizeOptions}
          />
        )}
      </PaginationWrapper>
    </PageWrapper>
  );
};

const Cards: React.FC<{cards: DashboardCardT[]}> = ({cards}) => {
  return (
    <DashboardCardsWrapper>
      {cards.map((el, i) => (
        <DashboardCard label={el.label} value={el.value} tooltip={el.tooltip} key={el?.label || i} />
      ))}
    </DashboardCardsWrapper>
  );
};

import {SelectOptionT} from '../types/helpers';
import {
  EmailsMapT,
  GroupDetailTableDataT,
  ticketColumnsT,
  TicketGroupCreateFields,
  TicketGroupCreateFormValues,
  TicketStatusTypes,
  TicketTypeRow,
} from '../types/tickets';
import {
  AddGuestsBodyT,
  getListTicketGroupsRes,
  GroupDetailTableT,
  guestItemT,
  guestState,
  ticketGroupBody,
  TicketGroupT,
  ticketGuestT,
  TicketT,
} from '../queries/types';
import {removeEmptyKeys} from './common';
import {DashboardTableDataT} from '../types/dashboard';
import {ticketState} from '../hooks/tickets';
import {CodeAccessTypes} from '../ui-kit/helpers/codes';
import {GroupedTicketTypesT} from './table';

const ORDER_URL = 'https://enchant.tix-web.d1tix.com/ticket_order/';

export const getTableTicketsFilterOptions = (): SelectOptionT[] => {
  return [
    {label: 'Show All', value: TicketStatusTypes.all},
    {label: 'Ticket sent', value: TicketStatusTypes.sent},
    // {label: 'Ticket redeemed', value: TicketStatusTypes.redeemed},
    {label: 'Pending', value: TicketStatusTypes.pending},
  ];
};

export const toCreateTicketGroupData = (values: TicketGroupCreateFormValues): ticketGroupBody => {
  return {
    [TicketGroupCreateFields.clientName]: values?.clientName || '',
    [TicketGroupCreateFields.clientEmail]: values?.clientEmailList || '',
    [TicketGroupCreateFields.contactPerson]: values?.contactPerson || '',
    [TicketGroupCreateFields.department]: values?.department || '',
    [TicketGroupCreateFields.ticketType]: values?.ticketType?.join(',') || '',
    [TicketGroupCreateFields.managedByUsers]: values?.managedByUsers || [],
    [TicketGroupCreateFields.description]: values?.description || '',
    [TicketGroupCreateFields.groupType]: values?.groupType || '',
    [TicketGroupCreateFields.ticketureOrderId]: values?.ticketureOrderId?.split('/').pop() || '',
    ...(values?.overrideDateField
      ? {
          metadata: {
            [TicketGroupCreateFields.overrideDateField]: values?.overrideDateField,
          },
        }
      : {}),
  };
};

export const toUpdateTicketGroupData = (
  values: TicketGroupCreateFormValues,
  old?: Partial<TicketGroupT>,
): ticketGroupBody => {
  const isOldTicketure = values?.ticketureOrderId?.split('/').pop() === old?.ticketureOrderId;
  return removeEmptyKeys({
    [TicketGroupCreateFields.clientName]: values?.clientName || '',
    [TicketGroupCreateFields.clientEmail]: values?.clientEmailList || '',
    [TicketGroupCreateFields.contactPerson]: values?.contactPerson || '',
    [TicketGroupCreateFields.department]: values?.department || '',
    [TicketGroupCreateFields.ticketType]: values?.ticketType?.join(',') || '',
    [TicketGroupCreateFields.managedByUsers]: values?.managedByUsers?.length ? values?.managedByUsers : undefined,
    [TicketGroupCreateFields.description]: values?.description || '',
    [TicketGroupCreateFields.groupType]: values?.groupType || '',
    [TicketGroupCreateFields.ticketureOrderId]: !isOldTicketure ? values?.ticketureOrderId?.split('/').pop() || '' : '',
    metadata: {
      ...(old?.metadata || {}),
      ...(values?.overrideDateField ? {[TicketGroupCreateFields.overrideDateField]: values?.overrideDateField} : {}),
    },
  }) as ticketGroupBody;
};

export const toInitialTicketGroupState = (values?: TicketGroupT): Partial<ticketGroupBody> => {
  return {
    [TicketGroupCreateFields.clientName]: values?.clientName,
    [TicketGroupCreateFields.clientEmail]: values?.clientEmailList?.length
      ? values?.clientEmailList?.join(', ')
      : values?.clientEmail,
    [TicketGroupCreateFields.contactPerson]: values?.contactPerson,
    [TicketGroupCreateFields.department]: values?.department,
    [TicketGroupCreateFields.ticketType]: values?.ticketType,
    [TicketGroupCreateFields.managedByUsers]: values?.managedByUsers,
    [TicketGroupCreateFields.description]: values?.description,
    [TicketGroupCreateFields.groupType]: values?.groupType,
    [TicketGroupCreateFields.ticketureOrderId]: values?.ticketureOrderId && ORDER_URL + values.ticketureOrderId,
    metadata: values?.metadata,
  };
};

export const groupsToTableState = (values: getListTicketGroupsRes): DashboardTableDataT[] => {
  return values.map((el) => ({
    key: el?.id,
    id: String(el?.id),
    clientName: {id: String(el?.id), name: el?.clientName},
    ticketType: el?.ticketType,
    department: el.department,
    contactPerson: el?.contactPerson,
    createdOn: el?.createdAt,
    totalTickets: el.ticketsUploaded,
    ticketsUsed: el.ticketsSent,
    status: {type: el?.outboundStatus, groupType: el?.groupType},
    actions: {id: String(el?.id)},
  }));
};

type guestIndex = {
  id?: number;
  email?: string;
  status?: CodeAccessTypes;
};

export const ticketsListToTableState = (
  values: TicketT[],
  guestInfo?: ticketGuestT,
  ticketTypes?: TicketTypeRow[],
): GroupDetailTableT => {
  const sortGuests = guestInfo?.guests
    ?.map((el) => {
      const updateTime = guestInfo?.ticketGroupGuests?.find((item) => item.id === el.guestId)?.updatedAt;
      return {...el, update: updateTime};
    })
    .sort((a, b) => +new Date(b.update + '') - +new Date(a.update + ''));

  const gEmails = sortGuests?.filter((el) => !!el?.guestEmail);
  const guestIndexes: guestIndex[] =
    gEmails?.map((guest, i) => {
      const isNotSend = guest?.tickets?.some((ticket) => ticket.outboundStatus === CodeAccessTypes.notsent);
      return {
        id: Number(guest?.guestId) || i,
        email: guest.guestEmail,
        status: isNotSend ? CodeAccessTypes.notsent : CodeAccessTypes.sent,
      };
    }) || [];

  const guestsToTable: GroupDetailTableDataT[] =
    sortGuests?.map((el, i) => {
      const item: guestIndex | undefined = guestIndexes[i];
      const id = el?.guestId || i;
      const admissionColumns = getTicketTypesColumns(el, ticketTypes, String(id));
      return {
        key: String(id),
        id: String(id),
        no: {no: String(Number(item?.id)), id: String(id)},
        email: {email: el?.guestEmail, edit: true, id: String(id)},
        name: {name: el?.guestName, email: el?.guestEmail, edit: true, id: String(id)},
        ...admissionColumns,
        actions: {
          id: String(id),
          outboundStatus: item?.status,
          ticketStatus: item?.status,
          email: el?.guestEmail,
        },
      };
    }) || [];
  const admissionColumns = getTicketTypesColumnsAddrow(ticketTypes);
  const addRow: GroupDetailTableDataT = {
    key: 'add',
    id: 'add',
    no: {no: 'add', id: 'add'},
    email: {addRow: true},
    name: {addRow: true},
    ...admissionColumns,
    actions: {addRow: true},
  };
  const result = [addRow, ...guestsToTable];
  return result;
};

export const toSelectOptions = (values: any[]): SelectOptionT[] => {
  return values.map((el) => ({label: el?.name, value: el?.id}));
};

export const toSelectDepOptions = (values: any[]): SelectOptionT[] => {
  return values.map((el) => ({label: el?.name, value: el?.name}));
};

export const validateEmail = (email?: string) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );
};

export const getTicketsCountText = (metrics?: TicketTypeRow[]) => {
  const counts = metrics
    ?.map((el) => (el?.ticketTypeUploaded ? `${el?.ticketDisplayName} ${el?.ticketTypeUploaded} tickets` : null))
    .filter((el) => !!el);
  const countText = counts?.length ? counts.join(', ') : 0;
  return `Ticket Counts: ${countText}`;
};

export const ticketStateToArray = (state: ticketState) => {
  const rows: {id?: string; email?: string; name?: string}[] = Object.keys(state).map((el) => ({
    id: el,
    email: state?.[el].email,
    name: state?.[el].name,
  }));
  return rows.filter((el) => validateEmail(el.email));
};

export const getTicketTypeCount = (guest: guestItemT, ttype: 'general' | 'skating' | 'parking') => {
  const res = guest?.tickets.filter((el) => el?.ticketGroupName.toLowerCase().includes(ttype));
  return Number(res?.[0]?.totalTickets || 0) || 0;
};

export const toAddGuestsBodyValues = (values?: guestState): guestState[] => {
  return [
    removeEmptyKeys<AddGuestsBodyT>({
      email: values?.email || '',
      name: values?.name || '',
      ...values,
      ////////// unset fields
      valid: undefined,
      validInfo: undefined,
    }),
  ];
};

export const getGuestTotalTickets = (values?: TicketTypeRow[]) => {
  return values?.reduce((prev, current) => prev + (current?.ticketTypeUploaded || 0), 0) || 0;
};

export const getTicketTypesColumns = (item?: guestItemT, ticketTypes?: TicketTypeRow[], id?: string) => {
  const columns = {};
  editableTicketTypes(ticketTypes)?.forEach((el) =>
    Object.assign(columns, {
      [el.ticketType]: {
        count: item?.tickets
          ?.filter((t) => correctTicketType(t?.ticketType) === el?.ticketType)
          .reduce((prev, next) => prev + Number(next.totalTickets), 0),
        id,
      },
    }),
  );

  return columns;
};

export const getTicketTypesColumnsAddrow = (ticketTypes?: TicketTypeRow[]) => {
  const tickets = {};
  ticketTypes?.forEach((el) => Object.assign(tickets, {[el.ticketType]: {addRow: true}})) || [];
  return tickets;
};

export const createTicketColumns = (types: string[]) =>
  types.map((el: string) => ({
    key: el?.toLowerCase()?.replaceAll(' ', '_'),
    title: el,
  })) as ticketColumnsT;

export type usedMetricsT = {[key: string]: number};
export const createMetricsUsed = (tickets?: TicketT[], ticketTypes?: TicketTypeRow[]) => {
  const used: usedMetricsT = {};
  const types = editableTicketTypes(ticketTypes);
  types?.forEach((el) => {
    used[el?.ticketType] = el?.ticketTypeAssigned;
  });
  // tickets
  //   ?.filter((el) => el?.guestEmail)
  //   .forEach((ticket) => {
  //     const tt = correctTicketType(ticket);
  //     if (tt) {
  //       used[tt] = used[tt] + 1;
  //     }
  //   });
  return used;
};

export const getAdmissionsFromInitial = (initialState?: GroupDetailTableDataT) => {
  const columns = {};
  initialState
    ? Object.keys(initialState)
        // eslint-disable-next-line
        // @ts-ignore
        .filter((el) => !!initialState[el]?.count)
        // eslint-disable-next-line
        // @ts-ignore
        .forEach((el) => Object.assign(columns, {[el]: Number(initialState?.[el]?.count)}))
    : [];

  return columns;
};

export const toEmailsMap = (groups?: TicketGroupT[]): EmailsMapT => {
  return groups?.map((el) => ({id: String(el?.id), email: el?.guestEmail})) || [];
};

export const correctTicketType = (ticketType?: string) => {
  if (ticketType?.toLowerCase().includes('minor') && ticketType?.includes('skating')) return 'minor_skating';
  return ticketType;
};

export const editableTicketTypes = (types?: TicketTypeRow[]) => types;

export const ticketTypesToTable = (tt?: GroupedTicketTypesT) => (tt ? Object.keys(tt) : []);

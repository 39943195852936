import {Modal as ANTDModal} from 'antd';
import styled from 'styled-components';
import {Media} from '../../ui-kit/theme/breakpoints';

export const ModalPdf = styled(ANTDModal)`
  width: 500px !important;
  height: 300px;

  ${Media.down.m} {
    width: 400px;
    height: 300px;
    .ant-modal-close-x {
      background: rgba(255, 255, 255, 0.2);
      border-radius: 100%;
    }
  }
  .ant-modal-footer {
    display: none;
  }
  .ant-modal-content {
    background-color: transparent;
    border-radius: none;
    padding: 0;
    box-shadow: none;
    height: 100%;
  }
  .ant-modal-body {
    padding: 0;
    height: 100%;
  }
`;

export const ModalWrapper = styled.div`
  background-color: ${({theme}) => theme.palette.background.white};
  border-radius: 20px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

export const ModalTitle = styled.h3`
  font-size: 20px;
  text-transform: capitalize;
`;

export const ModalSubtitle = styled.div`
  font-size: 12px;
  text-align: left;
  padding: 0 20px;
  max-width: 400px;
  width: 100%;
  margin: 0 auto;
  margin-bottom: 16px;
  box-sizing: border-box;
`;

export const ModalButtons = styled.div`
  align-items: center;
  gap: 10px;
  display: flex;
  button,
  a {
    &:hover {
      background-color: ${({theme}) => theme.palette.common.brown} !important;
      color: ${({theme}) => theme.palette.common.white} !important;
    }
  }
`;

export const CopyButtons = styled.div`
  align-items: center;
  gap: 20px;
  display: flex;
  margin-top: 6px;
`;

export const CopyButtonWrapper = styled.a`
  display: flex;
  align-items: center;
  gap: 6px;
  & > span {
    color: #111;
    text-decoration: underline;
  }
`;
